exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-algemenevoorwaarden-tsx": () => import("./../../../src/pages/algemenevoorwaarden.tsx" /* webpackChunkName: "component---src-pages-algemenevoorwaarden-tsx" */),
  "component---src-pages-blog-contentful-blog-post-slug-tsx": () => import("./../../../src/pages/blog/{ContentfulBlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacyverklaring-tsx": () => import("./../../../src/pages/privacyverklaring.tsx" /* webpackChunkName: "component---src-pages-privacyverklaring-tsx" */)
}

